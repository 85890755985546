/* styles.css */
.custom-scrollbar {
    @apply overflow-y-auto scroll-smooth;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    @apply bg-gray-200 dark:bg-gray-700;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-gray-400 dark:bg-gray-600 rounded;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500 dark:bg-gray-500;
  }


  .typing-indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 24px;
  }
  
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: bounce 1s infinite alternate;
  }
  
  .dot-2 {
    animation-delay: 0.2s;
  }
  
  .dot-3 {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-5px);
    }
  }
  